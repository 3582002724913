import { Link } from "gatsby";
import React, { Component } from "react";
import logo from "../images/icon_header.png"

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };
  }

  render() {
    const { data, header } = this.props;
    const { menu } = this.state;
    return (
      <header className={`site-header ${menu ? "active" : ""}`}>
        <div className="container">
          <div className="header-main">
            <div className="logo">
              <Link to="/">
                {logo ? (
                  <img src={logo} alt="logo" />
                ) : (
                  <span>風の中</span>
                )}
              </Link>
            </div>
            <div
              className="responsive-menu"
              onClick={() => {
                this.setState({
                  menu: !menu
                });
              }}
            >
              <span></span>
            </div>

            {header === "home" ? (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false
                    });
                  }}
                >
                  <li>
                    <a href="" onClick={e => {e.preventDefault();scrollTo("home");}}>Home</a>
                  </li>
                  <li>
                  <a href="" onClick={e => {e.preventDefault();scrollTo("About");}}>僕らが一人にならない為の唄。</a>
                  </li>
                  <li>
                  <a href="" onClick={e => {e.preventDefault();scrollTo("Albums");}}>Albums</a>
                  </li>
                  <li>
                  <a href="" onClick={e => {e.preventDefault();scrollTo("Songs");}}>Lyrics with Poetry</a>
                  </li>
                  <li>
                  <a href="" onClick={e => {e.preventDefault();scrollTo("Profile");}}>Profile</a>
                  </li>


                  {/*
                  <li>
                    <Link to={`/#Contact`}>Contact</Link>
                  </li>
                  {data.menus
                    .filter(item => item === "About")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#About`}>About</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Service")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Service`}>Service</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Blogs")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Blogs`}>Blogs</Link>
                        </li>
                      );
                    })}

                  {data.menus
                    .filter(item => item === "Work")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Work`}>Work</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Testimonials")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Testimonials`}>Testimonials</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Photos")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Photos`}>Photos</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Contact")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Contact`}>Contact</Link>
                        </li>
                      );
                    })}
                  */}


                </ul>

                </div>
            ) : (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false
                    });
                  }}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/songs">Lyrics with Poetry</Link>
                  </li>
                </ul>

              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

const scrollTo = (id) => {
  const top = document.getElementById(id).offsetTop;
  window.scrollTo({top: top - 85, behavior: "smooth"})
}